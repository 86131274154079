<template>
  <div>
    <el-row>
      <!--篩選區塊-->
      <!--<el-divider content-position="right"> 過濾條件 </el-divider>-->
      <div style="text-align: right;">
        <el-select v-model="listQuery.serviceId" size="small" v-bind:placeholder="$t('AllOperator')"
          class="filter-item-mid" v-if="serviceList.length > 0">
          <el-option key="0" :label="$t('AllOperator')" value="0"></el-option>
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'"
            :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.stationId" size="small" :placeholder="$t('AllChargingStation')" class="filter-item-mid">
          <el-option key="0" :label="$t('AllChargingStation')" value="0"></el-option>
          <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.type" size="small" :placeholder="$t('AllCategories')" class="filter-item-mid">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.label " :value="item.id" />
        </el-select>
        <!--
        <el-date-picker v-model="dateRange" size="small" class="filter-item" type="daterange"
          value-format="yyyy-MM-dd" range-separator="-" :start-placeholder="$t('Start')+$t('Date')"
          :end-placeholder="$t('End')+$t('Date')">
        </el-date-picker>
        -->
        <el-date-picker
            v-model="dateStart"
            :default-vaule="listQuery.startdate"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd">
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :default-vaule="listQuery.enddate">
        </el-date-picker>
        <el-input size="small" v-model="listQuery.searchTerm" placeholder="查詢交易編號或會員" class="filter-item" clearable style="width: 180px;"/>
      </div>
    </el-row>
    <el-row>

    </el-row>
    <el-divider content-position="right" >
      <span v-loading="statisticsLoading" style="font-weight: bold; font-size: 1.2em;">交易總充電度數: {{statisticsData.sumMeter}} 度，總金額: $ {{statisticsData.sumAmount}} 元</span>
    </el-divider>
    <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; top: -10px; padding-left: 10px;" >
      <span >產製時間: {{ reportDate }} </span>
    </el-row>
    <el-table v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }"
      @sort-change="tableSortChange"> <!--@row-click="showDetail"-->
      <el-table-column align="center" type="index" label="#" >
        <template slot-scope="scope">
          <span>{{(listQuery.page) * listQuery.size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" :label="$t('TradeId')" sortable />
      <el-table-column align="center" prop="service_id" :label="$t('Operator')" sortable="custom" v-if="serviceList.length > 0">
        <template slot-scope="scope">
          <span>{{scope.row.serviceName}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="app_account_id" :label="$t('Member')" sortable="custom" >
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.appAccountUser" placement="top-start">
              <span>{{scope.row.appAccountUser.replace(scope.row.appAccountUser.substr(6,4),"****")}}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="station_id" :label="$t('ChargingStation')" sortable="custom">
        <template slot-scope="scope">
          <span>{{scope.row.stationName}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="充電樁#槍">
        <template slot-scope="scope">
          <span v-if="scope.row.chargePointName==null">-</span>
          <span v-else>{{ scope.row.chargePointName + "#" + scope.row.connectorId }}</span>
        </template>
      </el-table-column>
      <!--<el-table-column align="center" prop="chargingSessionRecordId" :label="$t('ChargingSessionId')">
      </el-table-column>-->
      <el-table-column align="center" :label="$t('ChargingTime')">
        <template slot-scope="scope">
          {{ formatSecond(scope.row.totalChargingTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('ChargingMeter')">
        <template slot-scope="scope">
          {{ scope.row.totalChargingMeter }}度
        </template>
      </el-table-column>

      <el-table-column align="center" prop="amount" :label="$t('TradeAmount')" >
        <template slot-scope="scope">
          {{ scope.row.amount }}元
        </template>
      </el-table-column>
      <el-table-column align="center" prop="paymentType" :label="$t('TradeType')">
        <template slot-scope="scope">
          {{ formatPayType(scope.row.paymentType) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status" :label="$t('TradeStatus')">
        <template slot-scope="scope">
          <!--Modified by Lillian (20240603): payment_records之status:0=交易(請款)完成, -1=交易未完成, 1=待請款,
              2=退款中, 3=退款完成, 4=退款失敗, 5=補繳中, 6=補繳完成, 7=補繳失敗, 8=交易失敗(系統錯誤)-->
          <span style="color:#409EFF;" v-if="scope.row.status == 0">交易完成</span>
          <span style="color:#FF5733;" v-else-if="scope.row.status == -1">交易未完成</span>
          <span style="color:#7BA843;" v-else-if="scope.row.status == 1">請款中</span>
          <span style="color:#F333FF;" v-else-if="scope.row.status == 2">退款中</span>
          <span style="color:#F333FF;" v-else-if="scope.row.status == 3">退款完成</span>
          <span style="color:#F333FF;" v-else-if="scope.row.status == 4">退款失敗</span>
          <span style="color:#E6A23C;" v-else-if="scope.row.status == 5">補繳中</span>
          <span style="color:#F333FF;" v-else>交易失敗</span>
        </template>
      </el-table-column>
      <!--
      <el-table-column align="center" prop="paymentTradeId" :label="$t('TradePlatformId')">
        <template slot-scope="scope"> 
          {{  (scope.row.paymentTradeId == null?"未產生交易ID":scope.row.paymentTradeId) }}
        </template>
      </el-table-column>
      -->
      <el-table-column align="center" prop="create_time" :label="$t('Create')+$t('Date')" sortable="custom" >
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="70px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="showDetail(scope.row)" v-if="(scope.row.chargingSessionRecordId!=undefined && scope.row.chargingSessionRecordId!= null && scope.row.chargingSessionRecordId!= '')"><svg-icon iconName="view-details" /></el-button>
          <el-button type="primary" size="mini" v-else disabled><svg-icon iconName="view-details" /></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size"
      @pagination="getList" />
    <RecordDetail :dialogVisible="dialogDetailVisible" :activeTab="activeTab" :recordData="recordData"
      @close="dialogDetailOnClose()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import RecordDetail from './RecordDetail.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import { apiUrl } from "@/utils/main";
export default {
  name: 'Record',
  components: {
    Pagination,
    RecordDetail
  },
  mixins: [mixins],
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listQuery: {
        type: "0",
        serviceId: "0",
        stationId: "0",
        startdate: undefined,
        enddate: undefined,
        searchTerm: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      activeTab: "payment",
      serviceList: [],
      stationList: [],
      typeOptions: [

        { "id": "0",
          "label": this.$t('AllCategories')
        },
        //{ "name": "ocpp",
        //  "label": "ocpp"
        //},
        { "id": "1",
          "label": this.$t('CreditCard')
        },
        { "id": "2",
          "label": "LINE Pay"
        }
      ],
      dateRange: undefined,
      dateStart: undefined,
      dateEnd:   undefined,
      recordData: undefined,
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      dialogDetailVisible: false,
      groupData: undefined, // 群組資訊
      dialogPreviewVisible: false,
      previewGroupId: undefined, // 預覽群組ID
      profileGroupType: 'general',
      statisticsData: '',
      statisticsLoading: false,
      reportDate: this.formatCurrentDateTime()
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function (newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.enabled': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.serviceId': function () {
      this.listQuery.page = 0
      this.stationList = []
      /* 因為route傳過來的serviceId等同讓listQuery.serviceId發生變化,為了避免listQuery.stationId在這被洗成"0" 因此加上判斷 */
      if(this.$route.params.stationId != undefined) { 
        this.listQuery.stationId = Number(this.$route.params.stationId)
        this.$route.params.stationId = undefined 
      } else  
        this.listQuery.stationId = "0"
      /*--------------------------------------------------------------------------------------------------------------------*/ 
      this.getStationOptions()
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.stationId': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.type': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.searchTerm': function () {
      if(this.listQuery.searchTerm != undefined && this.listQuery.searchTerm.length > 0) {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
        this.dateStart = undefined
        this.dateEnd = undefined
      }
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateRange[0]
        this.listQuery.enddate = this.dateRange[1]
      } else {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
      }
      this.resetPage(this.getList)
      this.getStatistics()
    },
    */
    dateStart: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateStart
      } else {
        this.listQuery.startdate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.getStatistics()
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.enddate = this.dateEnd
      } else {
        this.listQuery.enddate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.getStatistics()
    },
  },
  created() {

    console.log(this.$route.params.start)
    if(this.$route.params.start != undefined) {
      this.listQuery.startdate = this.$route.params.start
      this.dateStart = this.$route.params.start
    }
    console.log(this.$route.params.start)
    if(this.$route.params.end != undefined) {
      this.listQuery.enddate = this.$route.params.end
      this.dateEnd = this.$route.params.end
    }
    //console.log(this.$route.query)
    if(this.$route.query.start != undefined) {
      //console.log(this.$route.query.start)
      this.listQuery.startdate = this.$route.query.start
      this.dateStart = this.$route.query.start
    }
    if(this.$route.query.end != undefined) {
      //console.log(this.$route.query.end)
      this.listQuery.enddate = this.$route.query.end
      this.dateEnd = this.$route.query.end
    }

    // 如果都沒有預設的內容，則顯示最近 24 小時的資訊
    if(this.listQuery.startdate==undefined && this.listQuery.enddate==undefined) {
      let date = new Date()
      let daysAgo = new Date()
      daysAgo = daysAgo.setDate(daysAgo.getDate()-7)
      this.listQuery.startdate = this.parseDateTime(daysAgo)
      this.dateStart = this.parseDateTime(daysAgo)
      this.dateEnd = this.parseDateTime(date)
      this.listQuery.enddate = this.parseDateTime(date)
    }
    
    //this.dateRange = [this.listQuery.startdate==undefined?"":this.listQuery.startdate, this.listQuery.enddate==undefined?"":this.listQuery.enddate]
    this.listQuery.startdate==undefined?"":this.listQuery.startdate
    this.listQuery.enddate==undefined?"":this.listQuery.enddate
    /* Dashboard.vue route過來的參數 by企分會議需求 */
    if(this.$route.params.serviceId != undefined) this.listQuery.serviceId = Number(this.$route.params.serviceId)
    if(this.$route.params.stationId != undefined) this.listQuery.stationId = Number(this.$route.params.stationId)    
    /* Dashboard.vue route過來的參數 by企分會議需求 */    
    
    this.dialogWidth = this.setDialogWidth()
    // await this.$store.dispatch("getUnitTable")

    this.getServiceOptions();
    this.getStationOptions();
    this.getStatistics();  
    
    //上面設定日期後觸發 getList 動作
    this.resetPage(this.getList)

    //refreshToken();
  },
  beforeDestroy(){
    console.log("call beforeDestroy");
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    async getList() {
      this.listLoading = true
      this.axios.get('/api/v1/payment', { params: this.listQuery }).then(res => {
        this.list = res.data.content
        // console.log(this.list)
        this.total = res.data.totalElements
        this.listLoading = false

        this.reportDate = this.formatCurrentDateTime()
      })
    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
    getStationOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/station/enabled', { params: this.listQuery }).then(res => {
        this.stationList = res.data
      })
    },
    async getStatistics() {
      this.statisticsLoading = true
      this.axios.get('/api/v1/payment/statistics', { params: this.listQuery }).then(res => {
        this.statisticsData = res.data
        this.statisticsLoading = false
      })
    },
    showDetail(row) {
      // console.log(row)
      this.dialogDetailVisible = true
      this.activeTab = "payment"
      this.recordData = row
    },
    dialogDetailOnClose() {
      this.dialogDetailVisible = false
      this.recordData = undefined
      //this.resetPage(this.getList)
    },
    fetchStationName(stationId){
      let stationName = stationId;
      this.stationList.forEach(function(station){
        if(station.id == stationId){
          stationName = station.name;
          return;
        }
      });
      return stationName;
    }

  }
}
</script>
<style lang="scss" scoped></style>
